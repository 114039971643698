<template>
    <div class="app-view">
        <v-container class="pt-0 mt-0">

            <div class="py-4">
                <h1>{{ $t("reset_password.header") }}</h1>
                <p class="my-0 py-0">{{ $t("reset_password.subheading") }}</p>
            </div>

            <v-card flat class="elevation-0 u-rounded">
                <v-card-text>
                    <v-form class="py-4" v-if="!isSaved" ref="form" v-model="valid" lazy-validation>
                        <v-text-field
                            v-model="user.email"
                            :label="$t('basic.email')"
                            :placeholder="$t('basic.email')"
                        ></v-text-field>
                        <v-text-field
                            type="password"
                            v-model="user.password"
                            :hint="$t('password.hint')"
                            :rules="[rules.required, rules.min]"
                            :label="$t('password.label')"
                            :password="$t('password.label')"
                        ></v-text-field>
                        <v-text-field
                            type="password"
                            v-model="passwordConfirm"
                            :hint="$t('password.hint')"
                            :rules="[rules.required, rules.min, passwordConfirmationRule]"
                            :label="$t('password.label_confirm')"
                            :placeholder="$t('password.label_confirm')"
                        ></v-text-field>
                        <div class="text-center">
                            <v-btn color="primary" depressed rounded outlined @click="validate">
                                {{ $t("password_reset.button") }}
                            </v-btn>
                        </div>
                    </v-form>

                    <v-alert tile dense prominent class="py-3" v-if="isSaved">
                        <p>{{ $t("password_reset.saved") }}</p>
                    </v-alert>

                    <v-alert tile dense prominemt class="py-3" v-if="isError">
                        {{ errorText }}
                    </v-alert>

                </v-card-text>
            </v-card>
        </v-container>
    </div>
</template>

<script>
export default {
    name: "PasswordReset",
    data() {
        const $t = this.$t.bind(this);
        return {
            valid: false,
            isLoading: false,
            isSaved: false,
            isError: false,
            errorText: "",
            rules: {
                required: value => !!value || $t("fields.general.rules.required"),
                min: v => (v && v.length >= 8) || $t("fields.general.rules.min", { min: 8 })
            },
            passwordConfirm: "",
            user: {
                email: "",
                password: "",
                token: ""
            }
        };
    },
    computed: {
        passwordConfirmationRule() {
            return () => (this.user.password === this.passwordConfirm) || this.$t("fields.general.rules.must_match", { field: "password" });
        }
    },
    methods: {
        validate() {
            if (this.$refs.form.validate()) {
                this.onResetPassword();
            }
        },
        onResetPassword() {
            this.isLoading = true;
            this.$store.dispatch("users/onResetPassword", this.user).then(() => {
                this.isLoading = false;
                this.isSaved = true;
                this.isError = false;
            }).catch((err) => {
                this.isLoading = false;
                this.isError = true;
                this.errorText = err;
            });
        },
        onGotoLogin() {
            this.$router.push("/");
        }
    },
    created() {
        const url = window.location.href;
        const array = url.split("/");
        const token = array[array.length - 2];
        const email = array[array.length - 1];
        this.user.token = token;
        this.user.email = email;
    }
};
</script>
